import {
  useCallback,
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { ENVIRONMENT, prodLike } from 'env'
import { SentryClient } from 'lib/sentry'
import { Cb } from 'cb'
import { LogRocketUtils } from 'lib/logrocket'

export type Tier = 'core' | 'plus' | 'plus-with-coi' | 'scale'

function getDefaultFlags() {
  return {
    // permanent flags
    showDevTools: true,
    showMultipleBankAccounts: true,
    businessTier: 'scale' as Tier,
    hideCosts: false,
    hideIncome: false,

    // temporary flags
    showProjectUpdates: false,
    showNewProjectDetail: false,
    showEstimateSections: false,
    showHideEstimateItem: false,
    showSetMarkupAcrossAllItems: false,
    allowPostSubtotalConfiguration: false,
    runOcrAutomatically: false,
    showMultiBillPayment: false,
    showRequestApprovalForDonna: false,
    showNewQboFlow: false,
    showProcoreIntegration: false,
    showCostTypes: false,
    showZeroAmountLineItems: false,
    showNewGuestBillDetail: false,
    allowCreateBufferedDiscounts: false,
  }
}

type Flags = ReturnType<typeof getDefaultFlags>

if (prodLike(ENVIRONMENT) && typeof window !== 'undefined') {
  LogRocketUtils.init()
}

type AnalyticsUser = {
  id: string
  authType: 'impersonator' | 'default'
  name: string
  email: string | null
  phoneNumber: string | null
  business: Cb.Business | null
}

type TAnalyticsContext = {
  identify: (user: AnalyticsUser) => void
  deIdentify: () => void
  flags: Flags
  setFlagOverrides: Dispatch<SetStateAction<Partial<Flags>>>
}
const AnalyticsContext = createContext<TAnalyticsContext | undefined>(undefined)

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)
  if (context === undefined)
    throw new Error('AnalyticsContext must be used within an AnalyticsProvider')
  return context
}

type Props = { children: ReactNode }

function AnalyticsProviderInner({ children }: Props) {
  const [user, setUser] = useState<AnalyticsUser | null>(null)
  const ldFlags = useFlags() as TAnalyticsContext['flags']
  const flags = ENVIRONMENT === 'test' ? getDefaultFlags() : ldFlags
  const [flagOverrides, setFlagOverrides] = useState<Partial<Flags>>({})
  const LDClient = useLDClient()

  const deIdentify = useCallback(() => {
    SentryClient.deidentify()
    setUser(null)
  }, [])

  const identify = useCallback((u: AnalyticsUser) => {
    SentryClient.identify({
      userId: u.id,
      email: u.email,
      phoneNumber: u.phoneNumber,
      isImpersonator: u.authType === 'impersonator',
    })
    LogRocketUtils.identify({
      id: u.id,
      name: u.name,
      businessName: u.business?.name,
      isGuest: !u.business,
      isImpersonator: u.authType === 'impersonator',
      email: u.email,
      phoneNumber: u.phoneNumber,
    })
    setUser(u)
  }, [])

  useEffect(() => {
    if (user && LDClient) {
      LDClient.identify({
        kind: 'multi',
        user: {
          key: user.id,
          name: user.name,
          email: user.email,
        },
        ...(user.business && {
          business: {
            kind: 'business',
            name: user.business.name,
            key: user.business.id,
          },
        }),
      })
    }
  }, [user, LDClient])

  return (
    <AnalyticsContext.Provider
      // eslint-disable-next-line
      value={{
        identify,
        deIdentify,
        flags:
          ENVIRONMENT === 'local' || ENVIRONMENT === 'dev' ? { ...flags, ...flagOverrides } : flags,
        setFlagOverrides,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export default function AnalyticsProvider({ children }: Props) {
  return <AnalyticsProviderInner>{children}</AnalyticsProviderInner>
}
