import { Action } from 'utils/actions'
import Typography from 'compass/data/Typography'
import { Cb, Q } from 'cb'
import { useSupportContext } from 'root/global'
import { t } from 'content'
import { X } from 'compass-local/legacy/icons'
import useScreenSize from 'compass/theme/useScreenSize'
import OnboardAccountForm from 'components/forms/OnboardAccountForm'
import { Module } from 'modules/routes'
import { ExpenseCardUtils } from 'features/expense-card/utils'
import useActivateAccountContext from 'features/onboarding/useActivateAccountContext'

function useQueries() {
  return Q.group({
    stripeConnectedAccountLink: Cb.useListStripeConnectedAccountLinks({
      select: Q.opt,
    }),
    bankAccounts: Cb.useListBankAccounts({
      params: { archived: false },
      select: Q.filter((x) => x.inbound_transfers_enabled),
    }),
    onboardingSurvey: Cb.useListOnboardingSurveys({ select: Q.opt }),
    cardProgram: Cb.useListCardPrograms({ select: Q.opt }),
  })
}

type BannerProps = {
  text: string
  action?: Action.Config
  closeAction?: () => void
}

function Banner({ text, action, closeAction }: BannerProps) {
  const sz = useScreenSize()

  return (
    <div className="w-full bg-th-orange-light2 px-5 py-3 flex justify-center md:-mb-2">
      <div className="w-full max-w-[1280px] flex justify-between items-center gap-3 text-th-brown-1">
        <Typography variant={sz === 'sm' ? 'caption' : 'body'}>{text}</Typography>
        <div className="flex gap-5 md:gap-10 items-center">
          {action && <Action.Mount key={action.name} {...action} />}
          {closeAction && (
            <X height={12} thickness={2.2} className="cursor-pointer" onClick={closeAction} />
          )}
        </div>
      </div>
    </div>
  )
}

export default function ActivateAccountBanner() {
  const queryset = useQueries()
  const updateOnboardingSurvey = Cb.usePartialUpdateOnboardingSurveyHook()
  const { setChatOpen } = useSupportContext()

  const { status, formData, controlledFormProps } = useActivateAccountContext()

  if (queryset.status !== 'success') return null

  const { stripeConnectedAccountLink, bankAccounts, onboardingSurvey, cardProgram } =
    queryset.queries

  const beamCardAvailable = stripeConnectedAccountLink?.business_state
    ? ExpenseCardUtils.availableInState(stripeConnectedAccountLink.business_state)
    : true
  const wantsBeamCard = onboardingSurvey?.wants_beam_card ?? false
  const beamCardAppCompleted =
    cardProgram &&
    (cardProgram.status === 'approved' ||
      cardProgram.status === 'processing' ||
      cardProgram.status === 'declined' ||
      cardProgram.status === 'pending' ||
      cardProgram.status === 'closed' ||
      cardProgram.status === 'unavailable_in_state')

  if (status === 'not-ready') return null

  return status === 'waiting-for-stripe' ? (
    <Banner
      text={t(
        "Business verification is in progress. If it's taking longer than 5 minutes, contact support.",
      )}
      action={Action.button(t('Contact support'), {
        onClick: () => setChatOpen(true),
        theme: 'text-small',
        endIcon: ['arrow', '90'],
      })}
    />
  ) : status === 'ready-for-first-submission' ? (
    <Banner
      text={
        wantsBeamCard
          ? t('Validate your business information before applying for the card.')
          : t('Activate your account to send and receive payments.')
      }
      action={Action.form(wantsBeamCard ? t('Validate business') : t('Activate account'), {
        Form: OnboardAccountForm,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
        qualify: () => formData,
        controlledFormProps,
      })}
    />
  ) : status === 'ready-for-resubmission' ? (
    <Banner
      text={t('Additional information is required to verify your business.')}
      action={Action.form(t('Provide additional information'), {
        Form: OnboardAccountForm,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
        qualify: () => formData,
        controlledFormProps,
      })}
    />
  ) : bankAccounts.length === 0 ? (
    <Banner
      text={t('Link your bank account to activate payments.')}
      action={Action.href(t('Link bank account'), {
        href: Module('/settings/bank-account').href,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
      })}
    />
  ) : beamCardAvailable && wantsBeamCard && !beamCardAppCompleted ? (
    <Banner
      text={t("You're ready to apply for the Beam Card.")}
      action={Action.href(t('Start card application'), {
        href: Module('/expense-cards').href,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
      })}
      closeAction={() => {
        if (onboardingSurvey) {
          updateOnboardingSurvey(onboardingSurvey.id, { wants_beam_card: false })
        }
      }}
    />
  ) : null
}
