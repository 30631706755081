import { formatCurrencyDONOTUSE } from 'utils/currency'
import Typography from 'compass/data/Typography'
import Card from 'compass/layout/Card'
import { Cb } from 'cb'
import { t, Trans } from 'content'
import LinkDONOTUSE from 'compass-local/Link2'
import { Module } from 'modules/routes'

type Props = {
  details: NonNullable<Cb.TaskNonadminResolveInvoiceDetails>
  close: () => void
}

export default function NonadminResolveInvoice({ details }: Props) {
  return (
    <div className="vflex gap-4 px-4 py-3">
      <div className="flex justify-between items-center gap-4">
        <span>
          <Trans
            tKey="Bill of <X>{{ X }}</X> was rejected."
            X={<Typography variant="bodybold" />}
            values={{ X: details ? formatCurrencyDONOTUSE(details.invoice_payment_amount) : '--' }}
          />
        </span>
        <LinkDONOTUSE href={Module(`/bills/${details.invoice_id}`).href}>
          <Typography variant="bodybold" className="text-th-orange-dark1">
            {t('View bill')}
          </Typography>
        </LinkDONOTUSE>
      </div>
      <Card variant="slate" className="vflex gap-6 !p-3">
        <div className="flex gap-4 justify-between">
          <div className="vflex gap-1">
            <Typography variant="captionbold">{details?.invoice_payee_name ?? ''}</Typography>
          </div>
          <Typography variant="captionbold">
            {formatCurrencyDONOTUSE(details?.invoice_payment_amount ?? '0')}
          </Typography>
        </div>
        <div className="vflex gap-1">
          <Typography variant="captionbold">{t('Rejection reason')}</Typography>
          {details?.latest_comments && (
            <Typography variant="caption" className="text-th-text-secondary">
              {t('“{{ X }}”', { X: details.latest_comments })}
            </Typography>
          )}
        </div>
      </Card>
    </div>
  )
}
