import { FC, ReactNode } from 'react'
import { unreachable } from 'msutils/misc'
import { ErrorPage, withBoundary } from 'utils/error'
import { execute } from 'utils/misc'
import LoadingPage from 'components/misc/LoadingPage'
import { Q } from 'cb'
import { MSError } from 'msutils'

type InjectedProps<T extends object> = T
type ClientProps<T extends object> = {
  _isRefetching?: boolean
  _queryset: T
}

type AwaitedQueryPrivateProps = '_queryset' | '_isRefetching'

export type AwaitedQueryInjectedProps<UseQuery> = UseQuery extends (_: any) => Q.Queryset<infer Q1>
  ? InjectedProps<Q1>
  : never

// eslint-disable-next-line mosaic-js/unnamed-args
export default function withInjectedQueriesDONOTUSE<T extends object, S extends ClientProps<T>>(
  useQueries: (props: Omit<S, AwaitedQueryPrivateProps>) => Q.Queryset<T>,
  Page: FC<S>,
  Loading?: ReactNode,
  Error?: ReactNode,
) {
  type Props = Omit<S, AwaitedQueryPrivateProps>

  return withBoundary(function Inner(props: Props) {
    const q = useQueries(props)

    return (
      <>
        {execute(() => {
          switch (q.status) {
            case 'loading':
              return Loading === undefined ? <LoadingPage /> : Loading
            case 'error':
              return Error === undefined ? (
                <ErrorPage error={new MSError.Error2('Error in query')} />
              ) : (
                Error
              )
            case 'success':
              return (
                <Page
                  {...({
                    ...props,
                    _isRefetching: q.isRefetching,
                    _queryset: { ...q.queries },
                  } as S)}
                />
              )
            default:
              return unreachable(q)
          }
        })}
      </>
    )
  }, 'query')
}
