import * as Cb from '../../client'
import { text } from './base'
import { config } from './config'
import * as UserUtils from './user'

type CreateBusinessProps = {
  props?: Partial<{
    name: string
    address: string
  }>
  auth: string
}

export function createBusiness({ props, auth }: CreateBusinessProps) {
  return Cb.createBusiness(
    {
      name: props?.name ?? "Thomas's Rocks",
      address_2: {
        type: 'raw',
        rich_details: null,
        raw_details: {
          raw_address: props?.address ?? 'Yolo County',
        },
      },
    },
    config(auth),
  )
}

type OnboardBusinessProps = {
  business: Cb.Business
  auth: string
}

export async function onboardBusiness({ business, auth }: OnboardBusinessProps) {
  const [bankAccount] = await Promise.all([
    Cb.createBankAccount(
      {
        business_id: business.id,
        routing_number: '110000000',
        account_number: '000123456789',
      },
      config(auth),
    ),
    Cb.onboardBusinessToStripe({ business_id: business.id }, config(auth)),
  ])

  await Cb.setInboundTransfersEnabled({ bank_account_id: bankAccount.id }, config())
}

type CreateVendor = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    email: string
    name: string
    phone: string
    address: string
  }>
}

export async function createVendor({ props, business, auth }: CreateVendor) {
  const vendor = await Cb.createPayeeContact(
    {
      name: props?.name ?? 'Thomas Electric',
      email: props?.email ?? `thomas+payee-${text(24)}@usebeam.co`,
      cc_emails: [],
      contact_type: 'contractor',
      phone: props?.phone ?? '3333333333',
      address_2: {
        type: 'raw',
        raw_details: {
          raw_address: props?.address ?? '123 Main St',
        },
        rich_details: null,
      },
      payer_id: business.id,
    },
    config(auth),
  )

  return vendor
}

type CreateClientProps = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    email: string
    name: string
    phone: string
    address: string
  }>
}

export async function createClient({ props, business, auth }: CreateClientProps) {
  const client = await Cb.createPayerContact(
    {
      name: props?.name ?? `Thomas Electric (${text(8)})`,
      email: props?.email ?? `thomas+payee-${text(24)}@usebeam.co`,
      cc_emails: [],
      contact_type: 'contractor',
      phone: props?.phone ?? '3333333333',
      address_2: {
        type: 'raw',
        raw_details: {
          raw_address: props?.address ?? '123 Main St',
        },
        rich_details: null,
      },
      payee_id: business.id,
    },
    config(auth),
  )

  return client
}

type SetupBusinessProps = {
  props?: Partial<{
    email: string
    userFullName: string
    businessName: string
    businessAddress: string
  }>
}

export async function setupBusiness({ props }: SetupBusinessProps) {
  const user = await UserUtils.createUser({
    props: {
      email: props?.email,
      fullName: props?.userFullName,
    },
  })

  if (!user.email) throw new Error('Created user is missing an email')

  const login = await UserUtils.createLogin({
    props: { email: user.email },
  })

  const auth = `Token ${login.session_token}`

  const business = await createBusiness({
    props: { name: props?.businessName, address: props?.businessAddress },
    auth,
  })

  return { auth, business, user }
}

type CreateBusinessOnboardingSurveyProps = {
  auth: string
  business: Cb.Business
}

export function createBusinessOnboardingSurvey(props: CreateBusinessOnboardingSurveyProps) {
  const { auth, business } = props
  return Cb.createOnboardingSurvey(
    {
      business_id: business.id,
      is_home_builder: true,
      is_remodeler: true,
      is_trade_contractor: true,
      is_light_commercial: true,
      num_projects_completed_yearly: 'more_than_twenty_five',
      wants_invoicing: true,
      wants_bill_payment: true,
      wants_estimating: true,
      wants_beam_card: true,
    },
    config(auth),
  )
}
