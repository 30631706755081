import * as Cb from '../../client'
import { config } from './config'
import * as BusinessUtils from './business'

type CreateEstimateProps = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    number: string
    displayName: string
    payerId: string
    sendEmail: boolean
  }>
}

export async function createEstimate({ auth, business, props }: CreateEstimateProps) {
  const payerId = props?.payerId ?? (await BusinessUtils.createClient({ business, auth })).payer_id
  const nextEstimateNumber = await Cb.getNextEstimateNumber(
    { payee_id: business.id, type: 'project' },
    config(auth),
  )

  const estimate = await Cb.createEstimate(
    {
      type: 'project',
      payee_id: business.id,
      payer_id: payerId,
      number: props?.number ?? nextEstimateNumber.number,
      display_name: props?.displayName ?? '19 Walter St.',
      address_2: {
        type: 'raw',
        raw_details: { raw_address: '19 Walter St., San Francisco' },
        rich_details: null,
      },
      expiration_date: '2024-10-24',
      additional_information: null,
      show_markup: false,
      show_sub_section_pricing: true,
      show_unit_costs: false,
      show_quantity: true,
      show_zero_amount_items: true,
      approval_signature_required: false,
      line_item_nodes: [
        {
          group_details: {
            cost_code_id: null,
            description: 'Group A',
            hide_line_items: false,
            is_section: false,
          },
          amount_details: null,
          is_inactive: false,
          children: [
            {
              group_details: null,
              amount_details: {
                cost_code_id: null,
                description: 'Line Item A',
                markup_multiplier: '0.25',
                fee_multiplier: null,
                is_post_subtotal: false,
                unit_type: '',
                unit_cost: '100',
                quantity: '1',
              },
              is_inactive: false,
              children: [],
            },
          ],
        },
      ],
      change_order_accounting_code_id: null,
      contract_id: null,
      files: [],
      cover_page: null,
      uploaded_cover_page: null,
    },
    config(auth),
  )

  if (props?.sendEmail) {
    await Cb.publishEstimate({
      estimate_id: estimate.id,
      send_email: true,
      email_body: null,
      cc_emails: [],
    })
  }

  return estimate
}
