import * as Cb from '../../client'
import { config } from './config'
import * as BusinessUtils from './business'

type CreateProjectProps = {
  auth: string
  props?: Partial<{
    name: string
    address: Cb.Address
    startDate: string
    endDate: string | null
  }>
}

export async function createProject({ auth, props }: CreateProjectProps) {
  const project = await Cb.createProject(
    {
      name: props?.name ?? '19 Walter St Reno',
      address: props?.address ?? {
        type: 'rich',
        rich_details: {
          line_1: '19 Walter St',
          line_2: '',
          city: 'San Francisco',
          zip_code: '94114',
          state: 'CA',
        },
        raw_details: null,
      },
      start_date: props?.startDate ?? '2022-10-20',
      end_date: props?.endDate ?? null,
      client_id: null,
      project_manager_id: null,
      property_owner_name: 'Owner',
      collect_lien_waivers: false,
      submit_lien_waivers: null,
    },
    config(auth),
  )
  return project
}

type CreateSubcontractProps = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    projectId: string
    payeeId: string
    startDate: string
  }>
}

export async function createSubcontract({ auth, business, props }: CreateSubcontractProps) {
  const projectId = props?.projectId ?? (await createProject({ auth })).id
  const payeeId = props?.payeeId ?? (await BusinessUtils.createVendor({ business, auth })).id

  const contract = await Cb.createContract(
    {
      project_id: projectId,
      payer_id: business.id,
      payee_id: payeeId,
      start_date: props?.startDate ?? '2022-10-20',
      contract_type: 'progress',
      files: [],
      items: [
        {
          contract_id: null,
          cost_code_id: null,
          description: 'Item 1',
          original_amount: '1000',
          amount_billed_externally: '0',
          retainage_multiplier: '0',
        },
      ],
    },
    config(auth),
  )
  return contract
}

type CreateSupContractProps = {
  auth: string
  business: Cb.Business
  props?: Partial<{
    projectId: string
    payerId: string
    startDate: string
  }>
}

export async function createSupContract({ auth, business, props }: CreateSupContractProps) {
  const projectId = props?.projectId ?? (await createProject({ auth })).id
  const payerId = props?.payerId ?? (await BusinessUtils.createClient({ business, auth })).id

  const contract = await Cb.createContract(
    {
      project_id: projectId,
      payer_id: payerId,
      payee_id: business.id,
      start_date: props?.startDate ?? '2022-10-20',
      contract_type: 'progress',
      files: [],
      items: [
        {
          contract_id: null,
          description: 'Item 1',
          cost_code_id: null,
          original_amount: '1000',
          amount_billed_externally: '0',
          retainage_multiplier: '0',
        },
      ],
    },
    config(auth),
  )
  return contract
}

export async function createFreeFormSupContract({ auth, business, props }: CreateSupContractProps) {
  const projectId = props?.projectId ?? (await createProject({ auth })).id
  const payerId = props?.payerId ?? (await BusinessUtils.createClient({ business, auth })).id

  const contract = await Cb.createContract(
    {
      project_id: projectId,
      payer_id: payerId,
      payee_id: business.id,
      start_date: props?.startDate ?? '2022-10-20',
      contract_type: 'free_form',
      files: [],
      items: [],
    },
    config(auth),
  )
  return contract
}

export async function createMilestoneSupContract({
  auth,
  business,
  props,
}: CreateSupContractProps) {
  const projectId = props?.projectId ?? (await createProject({ auth })).id
  const payerId = props?.payerId ?? (await BusinessUtils.createClient({ business, auth })).id

  const contract = await Cb.createContract(
    {
      project_id: projectId,
      payer_id: payerId,
      payee_id: business.id,
      start_date: props?.startDate ?? '2022-10-20',
      contract_type: 'milestone',
      files: [],
      items: [
        {
          contract_id: null,
          cost_code_id: null,
          amount_billed_externally: '0',
          retainage_multiplier: '0',
          description: 'First milestone',
          original_amount: '100.00',
        },
      ],
    },
    config(auth),
  )
  return contract
}
