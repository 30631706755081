import { useEffect, useRef } from 'react'
import type { AppProps } from 'next/app'
import 'styles/globals.css'
import 'content'
import { STORAGE_SOURCE_ORIGIN, STORAGE_TARGET_ORIGIN, REDIRECT_TO_TARGET } from 'env'
import { RedirectToTarget } from 'utils/shared-storage/Source'
import { MSDate, MSError2, useOnMount } from 'msutils'
import { SentryClient } from 'lib/sentry'
import 'navigator'
import 'core-js/actual/array/at'
import Root2 from 'root'
import { getAuth } from 'root/utils'
import { LogRocketUtils } from 'lib/logrocket'

const AssumeStaleAfterHours = 48

export default function App(props: AppProps) {
  const mountedAtTime = useRef(MSDate.date()).current
  const isImpersonator = getAuth()?.type === 'impersonator'

  useOnMount(() => {
    MSError2.report = (err) => {
      LogRocketUtils.getSessionUrl().then((sessionUrl) => {
        const reportError = new Error(typeof err === 'string' ? err : err.description)
        reportError.cause = typeof err === 'string' ? 'Unknown' : err.cause
        reportError.stack = typeof err === 'string' ? 'None' : err.stack
        reportError.name = typeof err === 'string' ? 'Unnamed error' : err.name
        SentryClient.report2(reportError, {
          tags: {
            isImpersonator,
            sessionUrl,
            displayMessage: err instanceof MSError2 ? err.message : 'None',
            componentStack: err instanceof MSError2 ? err.componentStack : 'None',
          },
        })
      })
    }
  })
  MSError2.useErrorHandling()

  useEffect(() => {
    const t = setInterval(() => {
      const currentTime = MSDate.date()
      const isStale = mountedAtTime.add(AssumeStaleAfterHours, 'hours') < currentTime

      if (isStale) {
        // eslint-disable-next-line
        console.warn('Forcing an app refresh to avoid stale client')
        window.location.reload()
      }
    }, 30000)

    return () => {
      clearInterval(t)
    }
  }, [mountedAtTime])

  return (
    <>
      {typeof window !== 'undefined' &&
        window.location.origin === STORAGE_SOURCE_ORIGIN &&
        STORAGE_TARGET_ORIGIN &&
        REDIRECT_TO_TARGET && <RedirectToTarget target={STORAGE_TARGET_ORIGIN} />}
      <Root2 {...props} />
    </>
  )
}
