import { ReactNode, useMemo } from 'react'
import { Cb, Q } from 'cb'
import { MSForm } from 'utils/form'
import { t } from 'content'
import { Module } from 'modules/routes'
import { useSupportContext } from 'root/global'
import useScreenSize from 'compass/theme/useScreenSize'
import useFormProps from 'utils/useFormProps'
import { Footer } from 'root/component-utils'
import {
  Calculator,
  Chat,
  CircledCheckmark,
  DollarExiting,
  Hammer,
} from 'compass-local/legacy/icons'
import Badge from 'compass-local/Badge'
import withInjectedQueriesDONOTUSE, { AwaitedQueryInjectedProps } from 'utils/withInjectedQueries'
import { ProjectUtils } from 'features/project'
import { useCurrentPath } from 'utils/router'
import { MSArray } from 'msutils'
import { useClientContext } from '../AppContext'
import NotificationsList from '../NotificationsList'
import {
  PageMaxWidth,
  PageScroller,
  NavAction,
  WithNavigation,
  PageHeight,
} from './component-utils'

function useQueries() {
  return Q.group({
    projects: Cb.useListProjects(),
    contracts: Cb.useListContracts(),
    vendors: Cb.useListPayeeContacts(),
    bills: Cb.useListBills(),
    estimates: Cb.useListEstimates({ params: { type: 'project' } }),
    changeOrders: Cb.useListEstimates({ params: { type: 'change_order' } }),
  })
}

type Props = {
  _queryset: AwaitedQueryInjectedProps<typeof useQueries>
  children: ReactNode
}

function GuestUiBase({ children, _queryset }: Props) {
  const { client } = useClientContext()
  const { vendors, contracts, projects, bills, estimates, changeOrders } = _queryset
  const sz = useScreenSize()
  const pathname = useCurrentPath()
  const { chatIsOpen, setChatOpen, chatUnreadMessagesCount } = useSupportContext()
  const notificationDrawerProps = useFormProps()
  const tasks = Cb.useListTasks({ refetchInterval: 30000 }).data ?? []
  const iconSize = sz === 'sm' ? 20 : 16

  const selectedProject =
    pathname.match(/\/projects\/(.*)\/.*/)?.at(1) || pathname.match(/\/projects\/(.*)/)?.at(1)
  const selectedBill = pathname.match(/\/bills\/(.*)/)?.at(1)
  const selectedProposedProject = pathname.match(/\/proposed-projects\/(.*)/)?.at(1)
  const selectedEstimate = pathname.match(/\/estimates\/(.*)/)?.at(1)
  const selectedChangeOrder = pathname.match(/\/change-orders\/(.*)/)?.at(1)

  const inProgressProjects = projects.filter((x) => !x.end_date)
  const completedProjects = projects.filter((x) => !inProgressProjects.some((y) => y.id === x.id))
  const rejectedEstimates = estimates.filter((x) => x.status === 'rejected')
  const unrejectedEstimates = estimates.filter(
    (x) => x.status !== 'rejected' && x.status !== 'void',
  )

  const topRoute = useMemo(() => pathname.split('/').at(1) ?? '', [pathname])
  const nonProjectBills = bills.filter((x) => !x.contract_id)

  return (
    <WithNavigation
      name={client.business_name || client.full_name}
      accountHref={Module('/dashboard').href}
      showAlert={false}
      mainActions={[
        sz !== 'sm' && { type: 'divider' },
        ...unrejectedEstimates
          .filter((x) => !x.created_project_id)
          .map((estimate) => {
            return NavAction(estimate.display_name, {
              icon: <Hammer height={iconSize} />,
              href: Module(`/proposed-projects/${estimate.id}`).href,
              isActive: selectedEstimate === estimate.id || selectedProposedProject === estimate.id,
              caption: vendors.find((x) => x.payee_id === estimate?.payee_id)?.name,
            })
          }),
        ...inProgressProjects.flatMap((project) => {
          const estimate = estimates.find((x) => x.created_project_id === project.id)
          const contract = contracts.find((x) => x.project_id === project.id)
          if (!contract && !estimate) return []
          return NavAction(ProjectUtils.getProjectDisplayName(project), {
            icon: <Hammer height={iconSize} />,
            href: Module(`/projects/${project.id}`).href,
            isActive:
              selectedProject === project.id ||
              !!bills
                .find((x) => x.id === selectedBill)
                ?.line_items.some((y) => y.project_id === project.id) ||
              estimates.find((x) => x.id === selectedEstimate)?.created_project_id === project.id ||
              changeOrders.some(
                (x) =>
                  x.id === selectedChangeOrder &&
                  contracts.some((y) => y.id === x.contract_id && y.project_id === project.id),
              ),

            caption: vendors.find(
              (x) => x.payee_id === contract?.payee_id || x.payee_id === estimate?.payee_id,
            )?.name,
          })
        }),
        (MSArray.isNonEmpty(completedProjects) ||
          MSArray.isNonEmpty(nonProjectBills) ||
          MSArray.isNonEmpty(rejectedEstimates)) && { type: 'divider' },
        MSArray.isNonEmpty(completedProjects) &&
          NavAction(t('Completed projects'), {
            icon: <CircledCheckmark height={iconSize} />,
            href: Module('/completed-projects').href,
            isActive:
              topRoute === 'completed-projects' ||
              completedProjects.some((x) => x.id === selectedProject),
          }),
        MSArray.isNonEmpty(rejectedEstimates) &&
          NavAction(t('Rejected estimates'), {
            icon: <Calculator height={iconSize} />,
            href: Module('/rejected-estimates').href,
            isActive:
              topRoute === 'rejected-estimates' ||
              rejectedEstimates.some((x) => x.id === selectedEstimate),
          }),
        MSArray.isNonEmpty(nonProjectBills) &&
          NavAction(t('One-off invoices'), {
            icon: <DollarExiting height={iconSize} />,
            href: Module('/one-off-invoices').href,
            isActive:
              topRoute === 'one-off-invoices' || nonProjectBills.some((x) => x.id === selectedBill),
          }),
      ]}
      secondaryActions={[
        { type: 'divider' },
        NavAction(t('Beam support'), {
          icon: <Chat height={iconSize} />,
          badge: (chatUnreadMessagesCount ?? 0) > 0 && (
            <Badge variant="light-orange">{chatUnreadMessagesCount}</Badge>
          ),
          onClick: () => setChatOpen(!chatIsOpen),
          isActive: chatIsOpen,
        }),
      ]}
    >
      <MSForm.Drawer {...notificationDrawerProps} title={t('Notifications')}>
        <NotificationsList tasks={tasks} close={notificationDrawerProps.setInactive} />
      </MSForm.Drawer>
      <PageScroller>
        <PageMaxWidth>
          <div className="vflex w-full">
            <PageHeight>
              <div className="grow">{children}</div>
            </PageHeight>
            <div className="hidden md:block">
              <Footer />
            </div>
          </div>
        </PageMaxWidth>
      </PageScroller>
    </WithNavigation>
  )
}

export default withInjectedQueriesDONOTUSE(useQueries, GuestUiBase)
