/*

error handling dimensions
* component error boundaries
* global unhandled errors
* inline js
* inline jsx
* reporting (i.e., what to do when you've caught an error)
  * the only place sentry client should be used is for setting this callback
* query errors
* mutation errors
* fallback (for inline reports)

MSError2.message - safe to display, since this will frequently end up accidentally displayed anyways
MSError2.description - report message
MSError2.name - safe to display, included in report

*/

import { ReactNode, useCallback } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useOnMount } from '../common'

export class MSError2 extends Error {
  static defaultMessage = 'An unexpected error has occurred'

  static report(error: MSError2 | string) {
    // eslint-disable-next-line
    console.log(error)
  }

  static DefaultFallback({ error }: { error?: MSError2 }) {
    return <>{error?.message ?? MSError2.defaultMessage}</>
  }

  static convertError(error: Error) {
    if (error instanceof MSError2) {
      return error
    } else {
      const newError = new MSError2(error.message, { name: 'WrappedError' })
      newError.stack = error.stack
      newError.cause = error.cause
      return newError
    }
  }

  /** There is no HOC for Boundary. If you're trying to wrap a component, rethink things - wrapping components is a good way to make boundaries O(N) instead of O(1). */
  static Boundary = (props: { children: ReactNode }) => {
    const Fallback = useCallback(({ error }: FallbackProps) => {
      if (error instanceof Error) {
        return <MSError2.DefaultFallback error={MSError2.convertError(error)} />
      } else {
        return <MSError2.DefaultFallback />
      }
    }, [])

    return <ErrorBoundary FallbackComponent={Fallback}>{props.children}</ErrorBoundary>
  }

  static useErrorHandling = () => {
    useOnMount(() => {
      const listener = (e: ErrorEvent) => {
        const err = e.error
        if (err instanceof MSError2) {
          if (!err._handled) {
            MSError2.report(err)
          }
        } else if (err instanceof Error) {
          MSError2.report(MSError2.convertError(err))
        } else {
          MSError2.report(new MSError2(JSON.stringify(err)))
        }
        return true
      }
      window.addEventListener('error', listener)
      return () => {
        window.removeEventListener('error', listener)
      }
    })
  }

  static HandledErrorDONOTUSE = (() => {
    const err = new MSError2('Handled')
    err._handled = true
    return err
  })()

  /** For errors caught by boundary */
  componentStack: string | null = null

  /** Reportable message */
  description: string

  /** DONOTUSE - if an error makes it to global scope, it *should* be reported */
  _handled: boolean = false

  constructor(description: string, options?: { message?: string; name?: string }) {
    super(options?.message ?? MSError2.defaultMessage)
    if (options?.name) {
      this.name = options.name
    }
    this.description = description
  }
}
