import { MSInputDONOTUSE } from 'msutils'

type InputOrValue<T> = { s: MSInputDONOTUSE.InputState<T, T, T, any, {}> } | { value: T }

export type InputProps<T> = InputOrValue<T> &
  Partial<{
    update: (newValue: T) => void
    focus: () => void
    blur: () => void
    error: string | null
    willUpdate: (oldValue: T, newValue: T) => void | Promise<void>
    didUpdate: (oldValue: T, newValue: T) => void | Promise<void>
    disabled: boolean
  }>

export function getInputProps<T, X extends object>(props: InputProps<T> & X) {
  if ('s' in props) {
    const { s, update, focus, blur, error, ...rest } = props
    const inputProps = MSInputDONOTUSE.getFields(s)

    // Note: each value able to be double-specified has an explicit merge strategy
    return {
      value: inputProps.value,
      update: (val: T) => {
        inputProps.update(val)
        update?.(val)
      },
      focus: () => {
        inputProps.focus()
        focus?.()
      },
      blur: () => {
        inputProps.blur()
        blur?.()
      },
      error: error === null ? null : error ?? inputProps.error ?? null,
      ...rest,
    }
  } else {
    return props
  }
}
