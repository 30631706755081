import { StripeConnectInstance } from '@stripe/connect-js'
import { ClosableLayout } from 'utils/ClosableLayout'

export namespace OnboardAccountFormUtils {
  type Props = {
    onSuccess: () => void
    stripeConnectInstance: StripeConnectInstance
  }

  export const fc = ClosableLayout.Controller<Props>('fsmodal')
}
