import { ReactNode } from 'react'
import NextLink from 'next/link'
import { Link2Utils } from '../utils'

type Props = Link2Utils.LinkTypeProps & {
  ui?: 'grey' | 'lightgrey' | 'none'
  children?: ReactNode
  stopPropagation?: boolean
}

export default function LinkDONOTUSE(props: Props) {
  const { external, href, ui = 'none', stopPropagation, children } = props
  const push = Link2Utils.usePush()

  return (
    <NextLink
      href={href}
      target={external ? '_blank' : undefined}
      onClick={(e) => {
        if (!external) {
          e.preventDefault()
          push(href)
        }
        if (stopPropagation) e.stopPropagation()
      }}
    >
      {ui === 'grey' ? (
        <span className="text-th-text-secondary hover:text-th-text underline">{children}</span>
      ) : ui === 'lightgrey' ? (
        <span className="text-slate-400 hover:text-slate-400 underline">{children}</span>
      ) : (
        children
      )}
    </NextLink>
  )
}
