import * as Sentry from '@sentry/nextjs'
import { enableOptionalServices, ENVIRONMENT, SENTRY_DSN } from 'env'
import { MSDate, MSError } from 'msutils'
import { getAuth } from 'root/utils'
import { consoleError, consoleLog } from 'utils/console'
import { execute } from 'utils/misc'
import { LogRocketUtils } from 'lib/logrocket'

const pageSetupTime = MSDate.format(MSDate.date(), { form: 'datetimeMedium' })
const disabled = !enableOptionalServices(ENVIRONMENT)

async function capture(e: Error, level: 'warning' | 'error' = 'error') {
  const sessionUrl = await LogRocketUtils.getSessionUrl()
  const isImpersonator = getAuth()?.type === 'impersonator'
  const tags = {
    pageSetupTime,
    sessionUrl,
    isImpersonator,
    level,
    innerMessage: execute(() => {
      const message = e instanceof MSError.Error2 ? e.wrappedError?.message : undefined
      return message ? message.replaceAll('\n', '  ') : message
    }),
  }

  Sentry.withScope((scope) => {
    scope.setLevel(level)
    Sentry.captureException(e, { tags })
  })
}

namespace SentryClient {
  if (!disabled)
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 1.0,
      environment: ENVIRONMENT,
      release: process.env.NEXT_PUBLIC_APP_VERSION,
    })

  export function report(error: MSError.Error2 | Error, level?: 'error' | 'warning') {
    if (disabled) {
      consoleLog('Sentry is disabled, so printing error instead')
      consoleLog(error)
      consoleLog(`message: ${error.message}`)
    } else {
      try {
        if (
          error instanceof MSError.Error2 &&
          error.wrappedError?.message === 'A network error occurred.'
        ) {
          capture(error, 'warning')
        } else {
          capture(error, level)
        }
      } catch (e) {
        consoleError('Encountered error trying to report error')
        consoleError(error)
      }
    }
  }

  export function report2(error: Error, props: { tags: object; level?: 'error' | 'warning' }) {
    if (disabled) {
      consoleLog('Sentry (v2) is disabled, so printing error instead')
      consoleLog(error)
      consoleLog(`message: ${error.message}`)
    } else {
      Sentry.withScope((scope) => {
        scope.setLevel(props.level ?? 'error')
        Sentry.captureException(error, {
          tags: { ...props.tags, pageSetupTime },
          fingerprint: [error.message],
        })
      })
    }
  }

  type IdentifyProps = {
    userId: string
    email: string | null
    phoneNumber: string | null
    isImpersonator: boolean
  }

  export function identify({ userId, email, phoneNumber, isImpersonator }: IdentifyProps) {
    if (!disabled) {
      Sentry.setUser({
        id: userId,
        email: email ?? undefined,
        username: email ?? phoneNumber ?? undefined,
      })
      Sentry.setTags({
        user_id: userId,
        user_email: email,
        phone_number: phoneNumber,
        is_impersonator: isImpersonator,
      })
    }
  }

  export function deidentify() {
    if (!disabled) {
      Sentry.setUser(null)
      Sentry.setTags({ user_id: null, user_email: null, phone_number: null, is_impersonator: null })
    }
  }
}

export { SentryClient }
