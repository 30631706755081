import { useState } from 'react'
import { Cb, Q } from 'cb'
import useFormProps from 'utils/useFormProps'
import { useOnChange } from 'msutils'
import { useStripeConnectContext } from 'root/global/Provider/StripeConnectProvider'
import { useBusinessContext } from 'root/user'

export default function useActivateAccountContext() {
  const [waitingForStripeResponse, setWaitingForStripeResponse] = useState(false)
  const [submissionVersion, setSubmissionVersion] = useState<string | null>(null)
  const q = Q.group({
    stripeConnectedAccountLink: Cb.useListStripeConnectedAccountLinks({
      select: Q.opt,
      refetchInterval: waitingForStripeResponse ? 5000 : undefined,
    }),
  })
  const { business } = useBusinessContext()
  const { stripeConnectInstance } = useStripeConnectContext()
  const onboardingFormProps = useFormProps()

  // There are a few situations where we want to more frequently poll the BE for updates:
  // 1. No connect account exists. We're waiting for the BE to create one.
  // 2. We have just submitted the form to Stripe and are waiting for the BE to
  //    get an update from Stripe.
  // 3. The BE is aware that Stripe is reviewing their information and we're
  //    waiting for additional updates from Stripe.
  useOnChange([q, business.has_onboarding_intent], () => {
    if (!business.has_onboarding_intent) return
    if (q.status !== 'success') return
    const { stripeConnectedAccountLink } = q.queries

    if (
      stripeConnectedAccountLink === null ||
      submissionVersion === stripeConnectedAccountLink.updated_at ||
      stripeConnectedAccountLink.has_requirements_pending_verification
    ) {
      setWaitingForStripeResponse(true)
    } else {
      setWaitingForStripeResponse(false)
    }
  })

  if (q.status !== 'success') {
    return {
      status: 'not-ready' as const,
      formData: null,
      controlledFormProps: onboardingFormProps,
    }
  }

  const { stripeConnectedAccountLink } = q.queries

  if (!stripeConnectedAccountLink || !stripeConnectInstance) {
    return {
      status: 'not-ready' as const,
      formData: null,
      controlledFormProps: onboardingFormProps,
    }
  }

  return {
    status:
      submissionVersion === stripeConnectedAccountLink.updated_at
        ? ('waiting-for-stripe' as const)
        : !stripeConnectedAccountLink.details_submitted
        ? ('ready-for-first-submission' as const)
        : stripeConnectedAccountLink.has_requirements_pending_verification
        ? ('waiting-for-stripe' as const)
        : stripeConnectedAccountLink.has_requirements_past_due ||
          stripeConnectedAccountLink.has_requirements_currently_due
        ? ('ready-for-resubmission' as const)
        : ('completed' as const),
    formData: {
      stripeConnectInstance,
      onSuccess: () => {
        setSubmissionVersion(stripeConnectedAccountLink.updated_at)
        onboardingFormProps.setInactive()
      },
    },
    controlledFormProps: onboardingFormProps,
  }
}
