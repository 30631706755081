import {
  createContext,
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useContext as useReactContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Q } from 'cb'
import Modal from 'compass/layout/Modal'
import Drawer from 'compass/layout/Drawer'
import { t } from 'content'
import { MSError, MSError2, useOnChange, xor } from 'msutils'
import { unreachable } from 'msutils/misc'
import { toast } from 'react-toastify'
import { Action } from 'utils/actions'
import Typography from 'compass/data/Typography'
import Icon from 'compass/data/Icon'
import Divider from 'compass/data/Divider'
import useScreenSize, { ScreenSize } from 'compass/theme/useScreenSize'
import { switchInline } from 'utils/misc'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'
import Alert from 'compass-local/Alert'
import { InputBaseContext } from 'compass-local/InputBase'
import { Chevron } from 'compass-local/legacy/icons'
import { cn } from 'msutils/classnames'
import useFormProps from 'utils/useFormProps'

export namespace ClosableLayout {
  const layouts = {
    modal: Modal,
    fsmodal: (props: { isActive: boolean; setInactive: () => void; children: ReactNode }) => (
      <Modal {...props} full />
    ),
    drawer: Drawer,
  }

  type TCtx = {
    setInactiveWithoutWarning: () => void
    type: keyof typeof layouts
  }

  const Ctx = createContext<TCtx | undefined>(undefined)
  export function useContext() {
    const ctx = useReactContext(Ctx)
    if (ctx === undefined) {
      throw new Error('ClosableLayoutContext must be used inside of a closable layout')
    }
    return ctx
  }

  export function useOptionalContext() {
    return useReactContext(Ctx)
  }

  type ClosableLayoutRef = {
    setInactive: () => void
  }
  type TImperativeHandleCtx = {
    handle: RefObject<ClosableLayoutRef>
    secondaryContentActive: boolean
    setSecondaryContentActive: (newValue: boolean) => void
  }

  const ImperativeHandleCtx = createContext<TImperativeHandleCtx | undefined>(undefined)
  export function useImperativeHandleContext() {
    const ctx = useReactContext(ImperativeHandleCtx)
    if (ctx === undefined) {
      throw new Error('ImperativeHandleContext must be used inside of a closable layout')
    }
    return ctx
  }

  type ControllerProps<TComponentProps extends object = {}, TQueryset extends object = {}> = {
    useQueries?: (props: TComponentProps) => Q.Queryset<TQueryset>
  }

  export function Controller<TComponentProps extends object = {}, TQueryset extends object = {}>(
    typeOrGetType: keyof typeof layouts | ((sz: ScreenSize) => keyof typeof layouts),
    controllerProps?: ControllerProps<TComponentProps, TQueryset>,
  ) {
    const PropsCtx = createContext<
      (TComponentProps & TQueryset & { setInactiveWithoutWarning: () => void }) | undefined
    >(undefined)
    const usePropsContext = () => {
      const propsCtxValue = useReactContext(PropsCtx)
      if (propsCtxValue === undefined) {
        throw new Error('Form context must be used within the form')
      }
      return propsCtxValue
    }

    const Form = (Body2: FC<Record<string, never>>) => {
      type EnhancedBodyProps = TComponentProps & {
        isActive: boolean
        setInactive: () => void
      }

      const useDefaultQueries = () => Q.group<{}>({}) as Q.Queryset<TQueryset>
      const useQueries = controllerProps?.useQueries ?? useDefaultQueries

      function EnhancedBody(props: EnhancedBodyProps) {
        const q = useQueries(props)
        const sz = useScreenSize()
        const type = useRef(
          typeof typeOrGetType === 'function' ? typeOrGetType(sz) : typeOrGetType,
        ).current
        const [secondaryContentActive, setSecondaryContentActive] = useState(sz !== 'md')
        const ctxValue = useMemo(
          () => ({ type, setInactiveWithoutWarning: props.setInactive }),
          [props.setInactive, type],
        )
        const handle = useRef<ClosableLayoutRef>(null)
        const propsCtxValue = useMemo(
          () =>
            q.status === 'success'
              ? { ...props, ...q.queries, setInactiveWithoutWarning: props.setInactive }
              : null,
          [props, q],
        )

        const handleCtx = useMemo(
          () => ({ secondaryContentActive, setSecondaryContentActive, handle }),
          [handle, secondaryContentActive],
        )

        useOnChange([props.isActive, q.status], () => {
          if (props.isActive && q.status === 'error') {
            const errorQ = Object.entries(q._queries).find(
              ([, query]) => (query as any)?.status === 'error',
            )
            MSError2.report(
              new MSError2('Error in queryset', {
                name: errorQ
                  ? `queryset[${errorQ[0]}] ${(errorQ[1] as any).error ?? 'Unknown Error'}`
                  : 'Unknown error',
              }),
            )
            toast.error(t('Something went wrong'))
            props.setInactive()
          }
        })

        const Layout = layouts[type]

        if (q.status === 'loading') {
          return null
        } else if (q.status === 'error') {
          return null
        } else if (q.status === 'success') {
          MSError.expect(propsCtxValue)
          return (
            <Layout
              isActive={props.isActive}
              setInactive={() => {
                if (handle.current) {
                  handle.current.setInactive()
                } else {
                  props.setInactive()
                }
              }}
            >
              <Ctx.Provider value={ctxValue}>
                <ImperativeHandleCtx.Provider value={handleCtx}>
                  <PropsCtx.Provider value={propsCtxValue}>
                    <Body2 />
                  </PropsCtx.Provider>
                </ImperativeHandleCtx.Provider>
              </Ctx.Provider>
            </Layout>
          )
        } else {
          return unreachable(q)
        }
      }

      return EnhancedBody
    }

    return { Form, useContext: usePropsContext }
  }
  type WarningOverlayProps = {
    closeForm: () => void
    dismissWarning: () => void
  }

  function WarningOverlay({ closeForm, dismissWarning }: WarningOverlayProps) {
    const sz = useScreenSize()

    return (
      <div className="vflex items-center">
        <div className="vflex gap-3">
          <Typography variant="title">{t('Discard changes?')}</Typography>
          <div className={sz === 'sm' ? 'vflex gap-3' : 'flex justify-end gap-3'}>
            <Button
              theme={theme2.ButtonThemeLight}
              onClick={(e) => {
                e.stopPropagation()
                closeForm()
              }}
            >
              {t('Discard')}
            </Button>
            <Button
              theme={theme2.ButtonThemeDefault}
              onClick={(e) => {
                e.stopPropagation()
                dismissWarning()
              }}
            >
              {t('Continue editing')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  type ModalPanelsProps = {
    secondary: ReactNode
    secondaryPosition: 'right' | 'left'
    isActive: boolean
    setIsActive: (newValue: boolean) => void
    children: ReactNode
  }

  function ModalPanels({
    secondary,
    secondaryPosition,
    isActive,
    setIsActive,
    children,
  }: ModalPanelsProps) {
    const sz = useScreenSize()
    const secondaryW = isActive ? (sz === 'md' ? 97 : 40) : 3
    const dividerX = secondaryPosition === 'left' ? secondaryW : 100 - secondaryW
    const knobWidth = 32
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)

    if (!secondary) {
      return <>{children}</>
    } else {
      return (
        <div
          className={cn(
            'flex h-full relative',
            secondaryPosition === 'right' && 'flex-row-reverse',
          )}
          ref={setContainerRef}
        >
          <div
            className="absolute top-[40px] border rounded-full bg-th-bg-white shadow-lg flex justify-center items-center cursor-pointer z-[2] transition"
            style={{
              left: ((containerRef?.clientWidth ?? 0) * dividerX) / 100 - knobWidth / 2,
              height: knobWidth,
              width: knobWidth,
            }}
            onClick={() => setIsActive(!isActive)}
          >
            <Chevron
              thickness={2.6}
              height={16}
              className={cn(
                'text-th-coolgrey-1 transition-all duration-100',
                xor(isActive, secondaryPosition === 'right') && 'rotate-180',
              )}
            />
          </div>
          <div className="bg-th-bg-slate h-full" style={{ width: `${secondaryW}%` }}>
            {isActive && secondary}
          </div>
          <div
            className="h-full border-l shadow-lg z-[1]"
            style={{ width: `${100 - secondaryW}%` }}
          >
            {(!isActive || sz !== 'md') && <div className="px-5 h-full flex">{children}</div>}
          </div>
        </div>
      )
    }
  }

  type ModalBodyProps = {
    overlay?: ReactNode
    title: string
    setInactive: () => void
    children: ReactNode
    actions: Action.Config[]
  }

  export function ModalBody({ overlay, title, setInactive, children, actions }: ModalBodyProps) {
    const sz = useScreenSize()

    return (
      <div className="vflex gap-5 p-6 h-full md:h-auto relative">
        {overlay}
        <div className="flex gap-8 items-center justify-between">
          <Typography variant="drawerheader">{title}</Typography>
          <Icon
            name="x"
            height={16}
            color="text-th-brown-2"
            cursor="pointer"
            onClick={setInactive}
          />
        </div>
        <div className="grow md:flex-auto">
          <InputBaseContext align="left">
            <Typography className="vflex gap-5">{children}</Typography>
          </InputBaseContext>
        </div>
        {Action.visibleCount(actions) > 0 && (
          <Action.UIConfigProvider value={{ theme: 'primary' }}>
            <Divider />
            {sz === 'sm' ? (
              <div className="vflex gap-2">
                {(actions ?? []).reverse().map((x, i) => (
                  <Action.Mount key={`${x.name}-${i}`} {...x} />
                ))}
              </div>
            ) : (
              <div className="flex gap-5 items-center justify-end">
                <div className="w-min flex gap-4 items-center">
                  {(actions ?? []).map((x, i) => (
                    <Action.Mount key={`${x.name}-${i}`} {...x} />
                  ))}
                </div>
              </div>
            )}
          </Action.UIConfigProvider>
        )}
      </div>
    )
  }

  type FsModalBodyProps = {
    overlay?: ReactNode
    title: string
    pageName?: string
    pageNumber?: `${number} / ${number}`
    setInactive: () => void
    error?: string | null
    secondaryContent?: ReactNode
    secondaryContentPosition?: 'right' | 'left'
    secondaryContentActive?: boolean
    setSecondaryContentActive?: (newValue: boolean) => void
    summary?: ReactNode
    back?: () => void
    actions: Action.Config[]
    children: ReactNode
  }

  export function FsModalBody({
    overlay,
    title,
    pageName,
    pageNumber,
    setInactive,
    error,
    secondaryContent,
    secondaryContentPosition,
    secondaryContentActive,
    setSecondaryContentActive,
    summary,
    back,
    actions,
    children,
  }: FsModalBodyProps) {
    return (
      <div className="vflex h-[100vh] relative overflow-hidden">
        {overlay}
        <div className="flex gap-8 items-center justify-between py-5 px-8 border-b">
          <div className="flex gap-10 items-center">
            <Typography variant="title">{title}</Typography>
            <div className="flex gap-2 items-baseline">
              {pageNumber && (
                <Typography variant="bodybold" className="text-th-coolgrey-1">
                  {pageNumber}
                </Typography>
              )}
              <Typography variant="bodybold">{pageName}</Typography>
            </div>
          </div>
          <Icon
            name="x"
            height={14}
            color="text-th-brown-2"
            cursor="pointer"
            onClick={setInactive}
          />
        </div>
        {error && (
          <div className="p-3">
            <Alert>{error}</Alert>
          </div>
        )}
        <ModalPanels
          secondary={secondaryContent}
          secondaryPosition={secondaryContentPosition ?? 'right'}
          isActive={secondaryContentActive ?? false}
          setIsActive={(newValue) => setSecondaryContentActive?.(newValue)}
        >
          <div className="grow md:flex-auto py-5 relative h-full overflow-y-scroll px-3">
            <InputBaseContext align="left">
              <div className="vflex gap-5 max-w-[840px] m-auto">{children}</div>
            </InputBaseContext>
          </div>
        </ModalPanels>
        {Action.visibleCount(actions) > 0 && (
          <div className="sticky bottom-0 z-[1] bg-[rgba(255,255,255,0.9)] rounded-2">
            <Divider />
            {summary && <div className="px-5 pt-3">{summary}</div>}
            <div className="flex gap-5 items-center justify-between px-8 py-3">
              <div className="w-fit">
                {back && (
                  <Button
                    onClick={back}
                    icon={<Icon name={['arrow', '-90']} />}
                    theme={theme2.ButtonThemeTextDark}
                  >
                    {t('Back')}
                  </Button>
                )}
              </div>
              <div className="w-min flex gap-4 items-center">
                <Action.UIConfigProvider value={{ theme: 'primary' }}>
                  {(actions ?? []).map((x, i) => (
                    <Action.Mount key={`${x.name}-${i}`} {...x} />
                  ))}
                </Action.UIConfigProvider>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  type DrawerBodyProps = {
    overlay?: ReactNode
    title: string
    pageName?: string
    pageNumber?: `${number} / ${number}`
    setInactive: () => void
    error?: string | null
    secondaryContent?: ReactNode
    secondaryContentTitle?: string
    secondaryContentPromptTitle?: string
    summary?: ReactNode
    back?: () => void
    size: 'small' | 'medium' | 'large'
    actions: Action.Config[]
    inlineFooterDONOTUSE?: ReactNode
    children: ReactNode
  }

  export function DrawerBody({
    overlay,
    title,
    pageName,
    pageNumber,
    setInactive,
    error,
    secondaryContent,
    secondaryContentTitle,
    secondaryContentPromptTitle,
    summary,
    back,
    size,
    actions,
    inlineFooterDONOTUSE,
    children,
  }: DrawerBodyProps) {
    const sz = useScreenSize()
    const width =
      sz === 'sm' ? undefined : switchInline(size, { small: 440, medium: 600, large: 1280 })
    const secondaryContentDrawerFormProps = useFormProps()

    const actionsWithSecondaryContent = [
      Action.button(secondaryContentPromptTitle ?? '', {
        theme: 'text',
        qualify: () => sz === 'sm' && !!secondaryContent,
        onClick: secondaryContentDrawerFormProps.setActive,
      }),
      ...(actions ?? []),
    ]

    return (
      <div className="vflex relative isolate h-full relative overflow-hidden" style={{ width }}>
        {overlay}
        <div className="vflex h-full overflow-y-scroll">
          <div className="sticky top-0 z-[1] bg-[rgba(255,255,255,0.9)] rounded-2">
            <div className="flex gap-8 p-5 md:px-8 md:pt-8 md:pb-5 items-center justify-between">
              <div className="w-fit">
                {back && (
                  <Button
                    onClick={back}
                    icon={<Icon name={['arrow', '-90']} />}
                    theme={theme2.ButtonThemeTextDark}
                  >
                    {t('Back')}
                  </Button>
                )}
              </div>
              <Icon
                name="x"
                height={16}
                color="text-th-brown-2"
                cursor="pointer"
                onClick={setInactive}
              />
            </div>
          </div>
          <div className="vflex px-5 md:px-8 grow">
            <div className="vflex gap-5">
              <Typography variant="drawerheader">{title}</Typography>
              {(pageName || pageNumber) && (
                <div className="flex gap-2 items-baseline">
                  {pageNumber && (
                    <Typography variant="bodybold" className="text-th-coolgrey-1">
                      {pageNumber}
                    </Typography>
                  )}
                  <Typography variant="title" className="text-th-grey-2">
                    {pageName}
                  </Typography>
                </div>
              )}
              {error && <Alert>{error}</Alert>}
            </div>
            {!!secondaryContent && sz === 'sm' && (
              <Drawer {...secondaryContentDrawerFormProps}>
                <DrawerBody
                  title={secondaryContentTitle ?? ''}
                  setInactive={secondaryContentDrawerFormProps.setInactive}
                  size="small"
                  actions={[
                    Action.button(t('Close'), {
                      theme: 'text',
                      onClick: secondaryContentDrawerFormProps.setInactive,
                    }),
                  ]}
                >
                  {secondaryContent}
                </DrawerBody>
              </Drawer>
            )}
            <InputBaseContext align="left">
              <div className="py-4 vflex gap-5">{children}</div>
            </InputBaseContext>
          </div>
          {Action.visibleCount(actionsWithSecondaryContent) > 0 && (
            <div className="sticky bottom-0 z-[1] bg-[rgba(255,255,255,0.9)] rounded-2">
              <div className="px-5 md:px-8">
                <Divider />
              </div>
              {sz === 'sm' ? (
                <div className="vflex gap-5 justify-between px-5 py-4">
                  {inlineFooterDONOTUSE}
                  {summary}
                  <div className="vflex gap-2 items-center">
                    <Action.UIConfigProvider value={{ theme: 'primary' }}>
                      {actionsWithSecondaryContent.reverse().map((x, i) => (
                        <Action.Mount key={`${x.name}-${i}`} {...x} />
                      ))}
                    </Action.UIConfigProvider>
                  </div>
                </div>
              ) : (
                <div className="flex gap-5 items-center justify-between px-8 py-4">
                  <div className="">{inlineFooterDONOTUSE}</div>
                  <div className="vflex gap-5">
                    {summary}
                    <div className="w-min flex gap-4 items-center">
                      <Action.UIConfigProvider value={{ theme: 'primary' }}>
                        {(actions ?? []).map((x, i) => (
                          <Action.Mount key={`${x.name}-${i}`} {...x} />
                        ))}
                      </Action.UIConfigProvider>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  type BodyProps = {
    title: string
    error?: string | null
    actions?: Action.Config[]
    /** Drawer */
    size?: 'small' | 'medium' | 'large'
    /** Drawer */
    inlineFooterDONOTUSE?: ReactNode
    /** Drawer, Fs-Modal */
    back?: () => void
    /** Drawer, Fs-Modal */
    secondaryContent?: ReactNode
    /** Drawer, Fs-Modal */
    secondaryContentPosition?: 'right' | 'left'
    /** Drawer, Fs-Modal */
    secondaryContentTitle?: string
    /** Drawer, Fs-Modal */
    secondaryContentPromptTitle?: string
    /** Drawer, Fs-Modal */
    summary?: ReactNode
    pageName?: string
    pageNumber?: `${number} / ${number}`
    customOverlay?: ReactNode
    warnOnClose?: boolean
    children: ReactNode
  }

  export function Body({
    title,
    back,
    error,
    actions,
    pageName,
    pageNumber,
    size = 'small',
    warnOnClose,
    customOverlay,
    secondaryContent,
    secondaryContentPosition,
    secondaryContentPromptTitle,
    secondaryContentTitle,
    summary,
    inlineFooterDONOTUSE,
    children,
  }: BodyProps) {
    const { handle, secondaryContentActive, setSecondaryContentActive } =
      useImperativeHandleContext()
    const [warningIsVisible, setWarningIsVisible] = useState(false)
    const { type, setInactiveWithoutWarning } = useContext()

    const maybeSetInactive = useCallback(() => {
      if (warnOnClose) {
        setWarningIsVisible(true)
      } else {
        setInactiveWithoutWarning()
      }
    }, [warnOnClose, setInactiveWithoutWarning])

    useImperativeHandle(handle, () => ({ setInactive: maybeSetInactive }), [maybeSetInactive])

    const overlay = (warningIsVisible || customOverlay) && (
      <div className="absolute bg-th-bg-white-transparent inset-0 p-9 vflex justify-center z-[3]">
        {warningIsVisible ? (
          <WarningOverlay
            closeForm={setInactiveWithoutWarning}
            dismissWarning={() => setWarningIsVisible(false)}
          />
        ) : (
          customOverlay
        )}
      </div>
    )

    if (type === 'modal') {
      return (
        <ModalBody
          title={title}
          overlay={overlay}
          setInactive={maybeSetInactive}
          actions={actions ?? []}
        >
          {children}
        </ModalBody>
      )
    } else if (type === 'fsmodal') {
      return (
        <FsModalBody
          title={title}
          overlay={overlay}
          setInactive={maybeSetInactive}
          actions={actions ?? []}
          back={back}
          pageName={pageName}
          pageNumber={pageNumber}
          summary={summary}
          secondaryContent={secondaryContent}
          secondaryContentPosition={secondaryContentPosition}
          secondaryContentActive={secondaryContentActive}
          setSecondaryContentActive={setSecondaryContentActive}
          error={error}
        >
          {children}
        </FsModalBody>
      )
    } else if (type === 'drawer') {
      return (
        <DrawerBody
          title={title}
          overlay={overlay}
          setInactive={maybeSetInactive}
          actions={actions ?? []}
          back={back}
          pageName={pageName}
          pageNumber={pageNumber}
          summary={summary}
          secondaryContent={secondaryContent}
          secondaryContentPromptTitle={secondaryContentPromptTitle}
          secondaryContentTitle={secondaryContentTitle}
          inlineFooterDONOTUSE={inlineFooterDONOTUSE}
          size={size}
          error={error}
        >
          {children}
        </DrawerBody>
      )
    } else {
      return unreachable(type)
    }
  }
}
